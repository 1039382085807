import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles/Calendar.css';

const AbsenceDialog = ({ isOpen, onClose, onSave }) => {
  const [absenceData, setAbsenceData] = useState({
    startTime: '',
    endTime: '',
    isFullDay: false,
    reason: '',
    selectedDates: [],
  });

  useEffect(() => {
    if (isOpen) {
      setAbsenceData({
        startTime: '',
        endTime: '',
        isFullDay: false,
        reason: '',
        selectedDates: [],
      });
    }
  }, [isOpen]);

  const isSaveDisabled = !absenceData.startTime || !absenceData.endTime || absenceData.selectedDates.length === 0 || !absenceData.reason;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAbsenceData({
      ...absenceData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setAbsenceData({
      ...absenceData,
      isFullDay: checked,
    });
  };

  const handleDateChange = (value) => {
    if (!Array.isArray(value)) {
      setAbsenceData(prev => ({ ...prev, selectedDates: [] }));
      return;
    }

    const [start, end] = value;
    console.log('Raw selected dates from calendar:', {
      start: start.toLocaleDateString('de-DE'),
      end: end.toLocaleDateString('de-DE')
    });

    const dates = [];
    const currentDate = new Date(start);
    const endDate = new Date(end);

    currentDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    console.log('After new Date():', {
      currentDate: currentDate.toLocaleDateString('de-DE'),
      endDate: endDate.toLocaleDateString('de-DE')
    });

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    console.log('Generated dates array:', dates.map(d => d.toLocaleDateString('de-DE')));

    setAbsenceData(prev => ({
      ...prev,
      selectedDates: dates
    }));
  };

  const handleSave = () => {
    console.log('Dates before formatting:', absenceData.selectedDates.map(d => d.toLocaleDateString('de-DE')));
    
    const formattedDates = absenceData.selectedDates.map(date => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    });
    
    const dataToSend = {
      startTime: absenceData.startTime,
      endTime: absenceData.endTime,
      isFullDay: absenceData.isFullDay,
      reason: absenceData.reason,
      selectedDates: formattedDates
    };
    
    console.log('Sending data:', dataToSend);
    onSave(dataToSend);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-[800px] p-6">
        <h2 className="text-xl font-bold mb-4">Abwesenheit hinzufügen</h2>
        <p className="text-sm text-gray-600 mb-4">Eine angelegte Abwesenheit bedeutet, dass dem Mitarbeiter für diesen Zeitraum Arbeitsstunden angerechnet werden.</p>
        
        <div className="flex gap-6">
          <div className="w-1/2">
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Startzeit</label>
              <input 
                type="time" 
                name="startTime"
                value={absenceData.startTime}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                disabled={absenceData.isFullDay}
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Endzeit</label>
              <input 
                type="time" 
                name="endTime"
                value={absenceData.endTime}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                disabled={absenceData.isFullDay}
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Grund für Abwesenheit angeben</label>
              <select 
                name="reason" 
                value={absenceData.reason}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="" disabled>Wählen Sie einen Grund</option>
                <option value="Krank">Krank</option>
                <option value="Kind Krank">Kind Krank</option>
                <option value="Angeordet">Angeordet</option>
                <option value="Urlaub">Urlaub</option>
                <option value="Zeitausgleich">Zeitausgleich</option>
                <option value="Feiertag">Feiertag</option>
              </select>
            </div>
          </div>
          
          <div className="w-1/2">
            <label className="block text-gray-700 mb-2">Tage auswählen</label>
            <div className="calendar-container">
              <Calendar
                onChange={handleDateChange}
                value={absenceData.selectedDates.length > 0 ? [
                  absenceData.selectedDates[0],
                  absenceData.selectedDates[absenceData.selectedDates.length - 1]
                ] : null}
                selectRange={true}
                className="w-full border border-gray-300 rounded p-2"
              />
            </div>
            {absenceData.selectedDates.length > 0 && (
              <div className="mt-2 text-sm text-gray-600">
                <p>Ausgewählte Tage: {absenceData.selectedDates.length}</p>
                <p className="mt-1">
                  {absenceData.selectedDates.length === 1 
                    ? absenceData.selectedDates[0].toLocaleDateString('de-DE')
                    : `${absenceData.selectedDates[0].toLocaleDateString('de-DE')} - ${absenceData.selectedDates[absenceData.selectedDates.length - 1].toLocaleDateString('de-DE')}`
                  }
                </p>
              </div>
            )}
          </div>
        </div>
        
        <div className="flex justify-end mt-4">
          <button 
            className={`bg-[#0000FF] text-white py-2 px-4 rounded-lg mr-2 ${isSaveDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            Hinzufügen
          </button>
          <button 
            className="text-[#0000FF] py-2 px-4 rounded-lg"
            onClick={onClose}
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );
};

export default AbsenceDialog;
