import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingBar from './LoadingBar';
import {
  HomeIcon,
  UsersIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  Cog6ToothIcon,
  ClockIcon,
  AdjustmentsHorizontalIcon,
  CreditCardIcon,
  PencilIcon,
  HeartIcon,
} from '@heroicons/react/24/outline';

import { ReactComponent as WhiteLogo } from './images/logo-white.svg';
import { classNames } from './utils/classUtil';

const brAdminNavigation = [
  { name: 'Zeitenverwaltung', href: '/time-management', icon: AdjustmentsHorizontalIcon, current: false },
  { name: 'Mitarbeiterverwaltung', href: '/employee-administration', icon: HeartIcon, current: false },
];

const initialNavigationData = [
  { name: 'Dashboard', href: '/home', icon: HomeIcon, current: true },
  { name: 'Meine Zeiten', href: '/myTimes', icon: ClockIcon, current: false },
  { name: 'Zeitenverwaltung', href: '/time-management', icon: AdjustmentsHorizontalIcon, current: false },
  { name: 'Kunden', href: '/clients', icon: UsersIcon, current: false },
  { name: 'Projekte', href: '/projects', icon: CreditCardIcon, current: false },
  { name: 'Tätigkeiten', href: '/tasks', icon: PencilIcon, current: false },
  { name: 'Export', href: '/export', icon: ArrowDownIcon, current: false },
];

const userNavigation = [
  { name: 'Dashboard', href: '/home', icon: HomeIcon, current: true },
  { name: 'Meine Zeiten', href: '/myTimes', icon: ClockIcon, current: false },
];

const miscellaneousNavigation = [
  {
    name: 'Impressum',
    href: '/imprint',
    icon: '',
    current: false,
  },
  {
    name: 'Datenschutzerklärung',
    href: '/data-protection',
    icon: '',
    current: false,
  },
  {
    name: 'Nutzungsbedingungen',
    icon: '',
    current: false,
  },
];

export default function SidebarNavigation({ setLoggedIn }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [navigation, setNavigation] = useState(null);
  const [admin, setAdmin] = useState(localStorage.getItem('admin'));
  const isBRAdmin = localStorage.getItem('isBRAdmin') === 'true';
  const token = localStorage.getItem('accessToken');

  function handleSignOut() {
    setNavigation(null);

    const prefixesToKeep = [
      'startTime_',
      'timerIsActive_',
      'timerStartTimestamp_',
      'timerTime_',
      'timerIsPaused_',
      'timerResumeTimestamp_',
    ];

    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      const shouldRemove = !prefixesToKeep.some(prefix => key.startsWith(prefix));

      if (shouldRemove) {
        localStorage.removeItem(key);
      }
    }

    setLoggedIn(false);
    navigate('/login');
  }

  useEffect(() => {
    const currentAdmin = localStorage.getItem('admin');
    setAdmin(currentAdmin);

    if (token) {
      if (isBRAdmin) {
        setNavigation(brAdminNavigation);
      } else if (currentAdmin === 'false' || currentAdmin === undefined) {
        setNavigation(userNavigation);
      } else {
        setNavigation(initialNavigationData);
      }
    } else {
      navigate('/login');
    }
  }, [token, navigate, isBRAdmin]);

  return (
    <div className="flex grow flex-col bg-primary px-6 h-full">
      <nav className="flex flex-1 flex-col mt-20">
        {navigation ? (
          <ul className="flex flex-1 flex-col gap-y-9">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? 'bg-primaryDark text-white'
                          : 'text-primaryLight hover:text-white hover:bg-primaryLightDark',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          location.pathname === item.href
                            ? 'text-white'
                            : 'text-primaryLight group-hover:text-white',
                          'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <div className="flex flex-1 flex-col gap-y-2 space-y-1">
                {!isBRAdmin && admin === 'true' && (
                  <Link
                    to="/employee-administration"
                    className={classNames(
                      location.pathname === '/employee-administration'
                        ? 'bg-primaryDark text-white'
                        : 'text-primaryLight hover:text-white hover:bg-primaryLightDark',
                      'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold'
                    )}
                  >
                    <HeartIcon
                      className="text-primaryLight group-hover:text-white h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Mitarbeiterverwaltung
                  </Link>
                )}
                {!isBRAdmin && (
                  <Link
                    to="/settings"
                    className={classNames(
                      location.pathname === '/settings'
                        ? 'bg-primaryDark text-white'
                        : 'text-primaryLight hover:text-white hover:bg-primaryLightDark',
                      'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold'
                    )}
                  >
                    <Cog6ToothIcon
                      className="text-primaryLight group-hover:text-white h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Einstellungen
                  </Link>
                )}
                <div
                  onClick={handleSignOut}
                  className="text-primaryLight hover:text-white hover:bg-primaryLightDark cursor-pointer group flex gap-x-3 rounded-md text-sm leading-6 font-semibold"
                >
                  <ArrowRightIcon
                    className="text-primaryLight group-hover:text-white h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  Ausloggen
                </div>
              </div>
            </li>

            {!isBRAdmin && (
              <li className="mb-10 mt-auto">
                <WhiteLogo className="w-12" />
                <div className="mt-4 flex flex-wrap">
                  {miscellaneousNavigation.map((item, index) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? 'bg-primaryDark text-white'
                          : 'text-primaryLight hover:text-white hover:bg-primaryLightDark',
                        'rounded-md text-xs font-semibold'
                      )}
                    >
                      {item.name}
                      {index < miscellaneousNavigation.length - 1 && (
                        <span>&nbsp;&middot;&nbsp;</span>
                      )}
                    </Link>
                  ))}
                </div>
                <br/>
                <p className='text-primaryLight hover:text-white hover:bg-primaryLightDark rounded-md text-xs font-semibold'>V.1.0.1</p>
              </li>
            )}
          </ul>
        ) : (
          <LoadingBar tiny />
        )}
      </nav>
    </div>
  );
}