import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as Realm from "realm-web";
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const Export = ({ setLoggedIn }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uniqueMonths, setUniqueMonths] = useState([]);
  const [approvedTimesData, setApprovedTimesData] = useState({});
  const [annualPlans, setAnnualPlans] = useState({});
  const [holidayPlans, setHolidayPlans] = useState({});
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const initialize = async () => {
      const isValid = await validateToken(token);
      if (!isValid) {
        if (stayLoggedIn) {
          const refreshed = await refreshAccessToken();
          if (!refreshed) {
            setLoggedIn(false);
            navigate('/login');
          } else {
            const newToken = app.currentUser.accessToken;
            const isValidNewToken = await validateToken(newToken);
            if (!isValidNewToken) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              setLoggedIn(true);
              await fetchData();
            }
          }
        } else {
          setLoggedIn(false);
          navigate('/login');
        }
      } else {
        setLoggedIn(true);
        await fetchData();
      }
    };

    initialize();
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [selectedYear]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  const fetchEmployees = async () => {
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token }),
      redirect: 'follow'
    };
  
    try {
      const response = await fetch(process.env.REACT_APP_EMPLOYEELIST_URL, requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching employee data:', result.error);
      } else {
        const sortedEmployees = result
          .map(employee => ({
            ...employee,
            displayName: `${employee.lastName}, ${employee.firstName}`
          }))
          .sort((a, b) => a.lastName.localeCompare(b.lastName));
          
        setEmployeeList(sortedEmployees);
        return sortedEmployees;
      }
    } catch (error) {
      console.error('Error:', error);
    }
    return [];
  };
  
  const fetchAnnualPlan = async (employeeId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        employeeId
      }),
      redirect: 'follow',
    };

    try {
      const response = await fetch(
        'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getSpecificAnnualPlan',
        requestOptions
      );
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching annual plan:', result.error);
        return null;
      }
      return result.annualPlan;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const fetchHolidayPlan = async (employeeId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        employeeId
      }),
      redirect: 'follow',
    };

    try {
      const response = await fetch(
        'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getSpecificHolidayPlan',
        requestOptions
      );
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching holiday plan:', result.error);
        return null;
      }
      return result.holidayPlan;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const fetchApprovedTimesForYear = async (employeeId, year) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        email: employeeId,
        year: year.toString()
      }),
      redirect: 'follow'
    };

    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching time entries:', result.error);
        return [];
      } else {
        return result.filter(time => time.status === 'approved');
      }
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const employees = await fetchEmployees();
    const months = new Set();
    const approvedTimesData = {};
    const annualPlans = {};
    const holidayPlans = {};

    await Promise.all(employees.map(async (employee) => {
      const approvedTimes = await fetchApprovedTimesForYear(employee.email, selectedYear);
      approvedTimesData[employee.employeeId] = approvedTimes;
      approvedTimes.forEach(time => {
        const date = new Date(time.date);
        const month = date.getMonth();
        months.add(month);
      });

      const annualPlan = await fetchAnnualPlan(employee.employeeId);
      annualPlans[employee.employeeId] = annualPlan;

      const holidayPlan = await fetchHolidayPlan(employee.employeeId);
      holidayPlans[employee.employeeId] = holidayPlan;
    }));

    setUniqueMonths([...months]);
    setApprovedTimesData(approvedTimesData);
    setAnnualPlans(annualPlans);
    setHolidayPlans(holidayPlans);
    setLoading(false);
  };

  const handleMonthSelection = (month) => {
    let newSelectedMonths;
    if (selectedMonths.includes(month)) {
      newSelectedMonths = selectedMonths.filter(m => m !== month);
    } else {
      newSelectedMonths = [...selectedMonths, month];
    }
    setSelectedMonths(newSelectedMonths);
  };

  const handleExportMonth = async (month) => {
    const employeesToExport = selectedEmployee
        ? employeeList.filter(employee => employee._id === selectedEmployee)
        : employeeList;

    const enrichedEmployees = await Promise.all(employeesToExport.map(async (employee) => {
        const approvedTimes = approvedTimesData[employee.employeeId]
            .filter(time => new Date(time.date).getMonth() === month)
            .sort((a, b) => new Date(a.date) - new Date(b.date));

        if (selectedEmployee) {
            let totalMinutes = 0;
            let totalPauseMinutes = 0;
            let totalStandortwechsel = 0;

            const timeRows = approvedTimes.map(time => {
                const [startHours, startMinutes] = time.startTime.split(':').map(Number);
                const [endHours, endMinutes] = time.endTime.split(':').map(Number);
                const start = new Date(time.date);
                const end = new Date(time.date);
                start.setHours(startHours, startMinutes, 0);
                end.setHours(endHours, endMinutes, 0);
                let durationMinutes = (end - start) / 60000;
                const [pauseHours, pauseMinutes] = time.pause.split(':').map(Number);
                durationMinutes -= (pauseHours * 60) + pauseMinutes;
                const durationHours = Math.floor(durationMinutes / 60);
                const durationRemainderMinutes = durationMinutes % 60;

                totalMinutes += durationMinutes;
                totalPauseMinutes += (pauseHours * 60) + pauseMinutes;

                const standortwechsel = time.standortwechsel ? 1 : 0;
                totalStandortwechsel += standortwechsel;

                return {
                    Datum: new Date(time.date).toLocaleDateString(),
                    Start: time.startTime,
                    End: time.endTime,
                    Pause: time.pause,
                    Dauer: `${durationHours}:${durationRemainderMinutes.toString().padStart(2, '0')}`,
                    Standortwechsel: standortwechsel
                };
            });

            const totalHours = Math.floor(totalMinutes / 60);
            const totalMinutesRemainder = totalMinutes % 60;
            const totalPauseHours = Math.floor(totalPauseMinutes / 60);
            const totalPauseRemainderMinutes = totalPauseMinutes % 60;

            timeRows.push({
                Datum: "Total",
                Pause: `${totalPauseHours}:${totalPauseRemainderMinutes.toString().padStart(2, '0')}`,
                Dauer: `${totalHours}:${totalMinutesRemainder.toString().padStart(2, '0')}`,
                Standortwechsel: totalStandortwechsel
            });

            let exportRows

            if (selectedEmployee) {
              exportRows = [
                  [{ v: `${employee.lastName} ${employee.firstName}` }, { v: employee.employeeId }], 
                  ["Datum", "Start", "End", "Pause", "Dauer", "Standortwechsel"], 
                  ...timeRows.map(row => [row.Datum, row.Start, row.End, row.Pause, row.Dauer, row.Standortwechsel]) 
              ];
            } else {
              console.log(timeRows)
              exportRows = [
                  ["Datum", "Start", "End", "Pause", "Dauer", "Standortwechsel"], 
                  ...timeRows.map(row => [row.Datum, row.Start, row.End, row.Pause, row.Dauer, row.Standortwechsel]) 
              ];
            }

            return exportRows;
        }

        const annualPlan = annualPlans[employee.employeeId];
        const holidayPlan = holidayPlans[employee.employeeId];
        let annualHours = 0;
        let gesamtMehrstunden = 0;
        let gesamtMinusstunden = 0;
        let gesamtKrank = 0;
        let gewaerterUrlaub = 0;
        let betrieblichMehrstunden = 0;
        let betrieblichMinusstunden = 0;
        let holidayValue = holidayPlan?.holidayValue || 0;
        let standortwechsel = 0;
        let dayProjects = {};

        approvedTimes.forEach(time => {
            const date = new Date(time.date);
            const weekday = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
            const dailyTarget = annualPlan?.[weekday] || 0;
            const [startHours, startMinutes] = time.startTime.split(':').map(Number);
            const [endHours, endMinutes] = time.endTime.split(':').map(Number);
            const start = new Date(time.date);
            const end = new Date(time.date);
            start.setHours(startHours, startMinutes, 0);
            end.setHours(endHours, endMinutes, 0);
            let durationMinutes = (end - start) / 60000;
            if (typeof time.pause === 'string') {
                const [pauseHours, pauseMinutes] = time.pause.split(':').map(Number);
                durationMinutes -= (pauseHours * 60) + pauseMinutes;
            }
            const durationHours = durationMinutes / 60;

            const dayKey = new Date(time.date).toISOString().split('T')[0];
            if (!dayProjects[dayKey]) {
                dayProjects[dayKey] = new Set();
            }
            dayProjects[dayKey].add(time.projectId);
            if (dayProjects[dayKey].size > 1) {
                standortwechsel += 1;
            }

            if (durationHours > dailyTarget) {
                gesamtMehrstunden += durationHours - dailyTarget;
                if (time.deviationComment === "Zeitausgleich (Betrieblich angeordnet)") {
                    betrieblichMehrstunden += durationHours - dailyTarget;
                }
            } else if (durationHours < dailyTarget) {
                gesamtMinusstunden += dailyTarget - durationHours;
                if (time.deviationComment === "Zeitausgleich (Betrieblich angeordnet)") {
                    betrieblichMinusstunden += dailyTarget - durationHours;
                }
            }
        });

        const approvedHours = approvedTimes.reduce((total, time) => {
            const [startHours, startMinutes] = time.startTime.split(':').map(Number);
            const [endHours, endMinutes] = time.endTime.split(':').map(Number);
            const start = new Date(time.date);
            const end = new Date(time.date);
            start.setHours(startHours, startMinutes, 0);
            end.setHours(endHours, endMinutes, 0);
            let durationMinutes = (end - start) / 60000;
            if (typeof time.pause === 'string') {
                const [pauseHours, pauseMinutes] = time.pause.split(':').map(Number);
                durationMinutes -= (pauseHours * 60) + pauseMinutes;
            }
            return total + (durationMinutes / 60);
        }, 0);

        const vorlaeufigeJahresprognose = approvedHours - annualHours;
        const gesamtAZK = gesamtKrank + gewaerterUrlaub;
        const resturlaub = holidayValue - gewaerterUrlaub;
        let monthlySoll = ""

        if (annualPlan) {
            let workingDaysInMonth = 0;
            const daysInMonth = new Date(selectedYear, month + 1, 0).getDate();
            for (let day = 1; day <= daysInMonth; day++) {
                const date = new Date(selectedYear, month, day);
                const dayOfWeek = date.getDay();
                if (dayOfWeek >= 1 && dayOfWeek <= 5) {
                    workingDaysInMonth += 1;
                }
            }
            const weeklyHours = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].reduce((sum, day) => {
                return sum + (annualPlan[day] || 0);
            }, 0);
            monthlySoll = (weeklyHours / 5) * workingDaysInMonth;
        } else {
            monthlySoll = 0;
        }

        return {
          lastName: employee.lastName,
          firstName: employee.firstName,
            employeeId: employee.employeeId,
            sollStunden: Number(monthlySoll).toFixed(2),
            istStunden: Number(approvedHours).toFixed(2),
            gesamtMehrstunden: Number(gesamtMehrstunden).toFixed(2),
            betrieblichMehrstunden: Number(betrieblichMehrstunden).toFixed(2),
            gesamtMinusstunden: Number(gesamtMinusstunden).toFixed(2),
            betrieblichMinusstunden: Number(betrieblichMinusstunden).toFixed(2),
            gewaerterUrlaub,
            gesamtKrank,
            JahresanspruchUrlaub: holidayValue,
            Resturlaub: resturlaub,
            standortwechsel
        };
    }));

    let worksheet

    if (!selectedEmployee) {
      worksheet = XLSX.utils.json_to_sheet(enrichedEmployees.flat());
    }

    if (selectedEmployee) {
      worksheet = XLSX.utils.aoa_to_sheet(enrichedEmployees.flat());
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Month_${monthNames[month]}`);
    XLSX.writeFile(workbook, `Month_${monthNames[month]}_${selectedYear}_Employees.xlsx`);
  };

  const handleExportSelectedMonths = async () => {
    const employeesToExport = selectedEmployee
        ? employeeList.filter(employee => employee._id === selectedEmployee)
        : employeeList;

    const enrichedEmployees = await Promise.all(employeesToExport.map(async (employee) => {
        const approvedTimes = approvedTimesData[employee.employeeId]
            .filter(time => selectedMonths.includes(new Date(time.date).getMonth()))
            .sort((a, b) => new Date(a.date) - new Date(b.date));

        if (selectedEmployee) {
          let totalMinutes = 0;
          let totalPauseMinutes = 0;
          let totalStandortwechsel = 0;

          const timeRows = approvedTimes.map(time => {
              const [startHours, startMinutes] = time.startTime.split(':').map(Number);
              const [endHours, endMinutes] = time.endTime.split(':').map(Number);
              const start = new Date(time.date);
              const end = new Date(time.date);
              start.setHours(startHours, startMinutes, 0);
              end.setHours(endHours, endMinutes, 0);
              let durationMinutes = (end - start) / 60000;
              const [pauseHours, pauseMinutes] = (typeof time.pause === 'string' ? time.pause : "00:00").split(':').map(Number);
              durationMinutes -= (pauseHours * 60) + pauseMinutes;
              const durationHours = Math.floor(durationMinutes / 60);
              const durationRemainderMinutes = durationMinutes % 60;

              totalMinutes += durationMinutes;
              totalPauseMinutes += (pauseHours * 60) + pauseMinutes;

              const standortwechsel = time.standortwechsel ? 1 : 0;
              totalStandortwechsel += standortwechsel;

              return {
                  Datum: new Date(time.date).toLocaleDateString(),
                  Start: time.startTime,
                  End: time.endTime,
                  Pause: time.pause,
                  Dauer: `${durationHours}:${durationRemainderMinutes.toString().padStart(2, '0')}`,
                  Standortwechsel: standortwechsel
              };
          });

          const totalHours = Math.floor(totalMinutes / 60);
          const totalMinutesRemainder = totalMinutes % 60;
          const totalPauseHours = Math.floor(totalPauseMinutes / 60);
          const totalPauseRemainderMinutes = totalPauseMinutes % 60;

          timeRows.push({
              Datum: "Total",
              Pause: `${totalPauseHours}:${totalPauseRemainderMinutes.toString().padStart(2, '0')}`,
              Dauer: `${totalHours}:${totalMinutesRemainder.toString().padStart(2, '0')}`,
              Standortwechsel: totalStandortwechsel
          });

          const headerRow = [
            [`${employee.lastName} ${employee.firstName}`, employee.employeeId],
            ["Datum", "Start", "End", "Pause", "Dauer", "Standortwechsel"]
          ];

          return [...headerRow, ...timeRows.map(row => [row.Datum, row.Start, row.End, row.Pause, row.Dauer, row.Standortwechsel])];
        }

        const annualPlan = annualPlans[employee.employeeId];
        const holidayPlan = holidayPlans[employee.employeeId];
        let annualHours = 0;
        let gesamtMehrstunden = 0;
        let gesamtMinusstunden = 0;
        let gesamtKrank = 0;
        let gewaerterUrlaub = 0;
        let betrieblichMehrstunden = 0;
        let betrieblichMinusstunden = 0;
        let holidayValue = holidayPlan?.holidayValue || 0;
        let standortwechsel = 0;
        let dayProjects = {};

        approvedTimes.forEach(time => {
            const date = new Date(time.date);
            const weekday = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
            const dailyTarget = annualPlan?.[weekday] || 0;
            const [startHours, startMinutes] = time.startTime.split(':').map(Number);
            const [endHours, endMinutes] = time.endTime.split(':').map(Number);
            const start = new Date(time.date);
            const end = new Date(time.date);
            start.setHours(startHours, startMinutes, 0);
            end.setHours(endHours, endMinutes, 0);
            let durationMinutes = (end - start) / 60000;
            if (typeof time.pause === 'string') {
                const [pauseHours, pauseMinutes] = time.pause.split(':').map(Number);
                durationMinutes -= (pauseHours * 60) + pauseMinutes;
            }
            const durationHours = durationMinutes / 60;

            const dayKey = new Date(time.date).toISOString().split('T')[0];
            if (!dayProjects[dayKey]) {
                dayProjects[dayKey] = new Set();
            }
            dayProjects[dayKey].add(time.projectId);
            if (dayProjects[dayKey].size > 1) {
                standortwechsel += 1;
            }

            if (durationHours > dailyTarget) {
                gesamtMehrstunden += durationHours - dailyTarget;
                if (time.deviationComment === "Zeitausgleich (Betrieblich angeordnet)") {
                    betrieblichMehrstunden += durationHours - dailyTarget;
                }
            } else if (durationHours < dailyTarget) {
                gesamtMinusstunden += dailyTarget - durationHours;
                if (time.deviationComment === "Zeitausgleich (Betrieblich angeordnet)") {
                    betrieblichMinusstunden += dailyTarget - durationHours;
                }
            }
        });

        const approvedHours = approvedTimes.reduce((total, time) => {
            const [startHours, startMinutes] = time.startTime.split(':').map(Number);
            const [endHours, endMinutes] = time.endTime.split(':').map(Number);
            const start = new Date(time.date);
            const end = new Date(time.date);
            start.setHours(startHours, startMinutes, 0);
            end.setHours(endHours, endMinutes, 0);
            let durationMinutes = (end - start) / 60000;
            if (typeof time.pause === 'string') {
                const [pauseHours, pauseMinutes] = time.pause.split(':').map(Number);
                durationMinutes -= (pauseHours * 60) + pauseMinutes;
            }
            return total + (durationMinutes / 60);
        }, 0);

        const vorlaeufigeJahresprognose = approvedHours - annualHours;
        const gesamtAZK = gesamtKrank + gewaerterUrlaub;
        const resturlaub = holidayValue - gewaerterUrlaub;
        let monthlySoll = "";

        if (annualPlan) {
            let workingDaysInSelectedMonths = 0;
            selectedMonths.forEach(month => {
                const daysInMonth = new Date(selectedYear, month + 1, 0).getDate();
                for (let day = 1; day <= daysInMonth; day++) {
                    const date = new Date(selectedYear, month, day);
                    const dayOfWeek = date.getDay();
                    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
                        workingDaysInSelectedMonths += 1;
                    }
                }
            });
            const weeklyHours = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].reduce((sum, day) => {
                return sum + (annualPlan[day] || 0);
            }, 0);
            monthlySoll = (weeklyHours / 5) * workingDaysInSelectedMonths;
        } else {
            monthlySoll = 0;
        }

        return {
            lastName: employee.lastName,
            firstName: employee.firstName,
            employeeId: employee.employeeId,
            sollStunden: Number(monthlySoll).toFixed(2),
            istStunden: Number(approvedHours).toFixed(2),
            gesamtMehrstunden: Number(gesamtMehrstunden).toFixed(2),
            betrieblichMehrstunden: Number(betrieblichMehrstunden).toFixed(2),
            gesamtMinusstunden: Number(gesamtMinusstunden).toFixed(2),
            betrieblichMinusstunden: Number(betrieblichMinusstunden).toFixed(2),
            gewaerterUrlaub,
            gesamtKrank,
            JahresanspruchUrlaub: holidayValue,
            Resturlaub: resturlaub,
            standortwechsel
        };
    }));

    let worksheet

    if (!selectedEmployee) {
      worksheet = XLSX.utils.json_to_sheet(enrichedEmployees.flat());
    }

    if (selectedEmployee) {
      worksheet = XLSX.utils.aoa_to_sheet(enrichedEmployees.flat());
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');
    XLSX.writeFile(workbook, 'SelectedMonths_Employees.xlsx');
  };

  const handleExportFullYear = async () => {
    const employeesToExport = selectedEmployee
      ? employeeList.filter(employee => employee._id === selectedEmployee)
      : employeeList;
  
    const enrichedEmployees = await Promise.all(employeesToExport.map(async (employee) => {
      const annualPlan = annualPlans[employee.employeeId];
      const holidayPlan = holidayPlans[employee.employeeId];
      const approvedTimes = approvedTimesData[employee.employeeId];
  
      let annualHours = 0;
      let gesamtMehrstunden = 0;
      let gesamtMinusstunden = 0;
      let gesamtKrank = 0;
      let gewaerterUrlaub = 0;
      let betrieblichMehrstunden = 0;
      let betrieblichMinusstunden = 0;
      let holidayValue = employee?.holidayValue || 0;
      let standortwechsel = 0;
      let dayProjects = {};
  
      if (annualPlan) {
        const weeklyHours = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].reduce((sum, day) => {
          return sum + (annualPlan[day] || 0);
        }, 0);
        annualHours = weeklyHours * 52;
      }
  
      if (holidayPlan?.timeslots && annualPlan) {
        holidayPlan.timeslots.forEach(day => {
          if (day.status !== 'Wochenende') {
            const date = new Date(day.date);
            const weekday = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
            annualHours -= (annualPlan[weekday] || 0);
            if (day.status === 'krank') {
              gesamtKrank += 1;
            }
            if (day.status === 'Urlaub') {
              gewaerterUrlaub += 1;
            }
          }
        });
      }
  
      approvedTimes.forEach(time => {
        const date = new Date(time.date);
        const weekday = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
        const dailyTarget = annualPlan?.[weekday] || 0;
        const [startHours, startMinutes] = time.startTime.split(':').map(Number);
        const [endHours, endMinutes] = time.endTime.split(':').map(Number);
        const start = new Date(time.date);
        const end = new Date(time.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        let durationMinutes = (end - start) / 60000;
        if (typeof time.pause === 'string') {
          const [pauseHours, pauseMinutes] = time.pause.split(':').map(Number);
          durationMinutes -= (pauseHours * 60) + pauseMinutes;
        }
        const durationHours = durationMinutes / 60;
  
        const dayKey = new Date(time.date).toISOString().split('T')[0];
        if (!dayProjects[dayKey]) {
          dayProjects[dayKey] = new Set();
        }
        dayProjects[dayKey].add(time.projectId);
        if (dayProjects[dayKey].size > 1) {
          standortwechsel += 1;
        }
  
        if (durationHours > dailyTarget) {
          gesamtMehrstunden += durationHours - dailyTarget;
          if (time.deviationComment === "Zeitausgleich (Betrieblich angeordnet)") {
            betrieblichMehrstunden += durationHours - dailyTarget;
          }
        } else if (durationHours < dailyTarget) {
          gesamtMinusstunden += dailyTarget - durationHours;
          if (time.deviationComment === "Zeitausgleich (Betrieblich angeordnet)") {
            betrieblichMinusstunden += dailyTarget - durationHours;
          }
        }
      });
  
      const approvedHours = approvedTimes.reduce((total, time) => {
        const [startHours, startMinutes] = time.startTime.split(':').map(Number);
        const [endHours, endMinutes] = time.endTime.split(':').map(Number);
        const start = new Date(time.date);
        const end = new Date(time.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        let durationMinutes = (end - start) / 60000;
        if (typeof time.pause === 'string') {
          const [pauseHours, pauseMinutes] = time.pause.split(':').map(Number);
          durationMinutes -= (pauseHours * 60) + pauseMinutes;
        }
        return total + (durationMinutes / 60);
      }, 0);
  
      const vorlaeufigeJahresprognose = approvedHours - annualHours;
      const gesamtAZK = gesamtKrank + gewaerterUrlaub;
      const resturlaub = holidayValue - gewaerterUrlaub;

      return {
        lastName: employee.lastName,
        firstName: employee.firstName,
        employeeId: employee.employeeId,
        sollStunden: Number(annualHours).toFixed(2),
        istStunden: Number(approvedHours).toFixed(2),
        vorlaeufigeJahresprognose: Number(vorlaeufigeJahresprognose).toFixed(2),
        gesamtMehrstunden: Number(gesamtMehrstunden).toFixed(2),
        betrieblichMehrstunden: Number(betrieblichMehrstunden).toFixed(2),
        gesamtMinusstunden: Number(gesamtMinusstunden).toFixed(2),
        betrieblichMinusstunden: Number(betrieblichMinusstunden).toFixed(2),
        gesamtAZK,
        gewaerterUrlaub,
        gesamtKrank,
        JahresanspruchUrlaub: holidayValue,
        Resturlaub: resturlaub,
        standortwechsel
      };
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(enrichedEmployees);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');
    XLSX.writeFile(workbook, 'Yearly_Employees.xlsx');
  };

  const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => currentYear - i).sort((a, b) => b - a);

  return (
    <div className="px-[83px] py-[48px]">
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
        }}>
          <Oval
            height={80}
            width={80}
            color="#0000FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#0000FF"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <h1 className="text-[24px] leading-[32px] font-[700]">Zeitexport</h1>
          <p className='text-[12px] leading-[18px] font-[500] mb-[34px]'>Erfasste Mitarbeiterzeiten exportieren</p>
          <p className='text-[12px] leading-[18px] font-[500]'>Jahr auswählen</p>
          <div className="flex items-center mb-[16px] space-x-4 h-[40px]">
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
              className="border rounded py-2 px-3 w-[60%] h-[40px]"
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <button className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-[8px] w-[20%] h-[40px] flex items-center justify-center" onClick={handleExportSelectedMonths}>
              {selectedMonths.length} Dateien
              <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
            </button>
            <button className={`bg-[#0000FF] text-white py-2 px-4 rounded-[8px] ${loading ? 'cursor-wait' : 'cursor-pointer'} w-[20%] h-[40px] flex items-center justify-center`} onClick={handleExportFullYear} disabled={loading}>
              Export Jahr {selectedYear}
              <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
            </button>
          </div>
          
          <div className="flex items-center mb-[16px] space-x-4 h-[40px]">
            <select
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
              className="border rounded py-2 px-3 w-[58.25%] h-[40px]"
            >
              <option value="">Nach Mitarbeiter filtern</option>
              {employeeList.map((employee) => (
                <option key={employee._id} value={employee._id}>
                  {employee.displayName}
                </option>
              ))}
            </select>
          </div>

          <div>
            {uniqueMonths.length > 0 ? (
              uniqueMonths
                .sort((a, b) => {
                  const currentMonth = new Date().getMonth();
                  
                  if (a === currentMonth) return -1;
                  if (b === currentMonth) return 1;
                  return a - b;
                })
                .map(month => (
                  <div key={month} className="flex items-center justify-between h-[80px] p-4 border-b bg-[#FCFCFC] mb-[8px] rounded-[3px] shadow-sm">
                    <div className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        checked={selectedMonths.includes(month)}
                        onChange={() => handleMonthSelection(month)}
                      />
                      <span className="text-[16px] leading-[18px] font-[700]">
                        {monthNames[month]} {selectedYear}
                      </span>
                    </div>
                    <a href="#" className="text-[#0000FF]" onClick={() => handleExportMonth(month)}>
                      Download {monthNames[month]}
                    </a>
                  </div>
                ))
            ) : (
              <p>Keine Monate gefunden</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Export;